<template>
  <div>
    <!-- dialog -->
    <van-dialog
      :before-close="setOrderBefore"
      v-model="setOrderModal"
      show-cancel-button
      title="确认提交"
    >
    </van-dialog>
    <!-- dialog -->
    <!-- top -->
    <van-sticky>
      <van-nav-bar
        title="订单信息"
        left-arrow
        @click-left="$router.push('/ordersC')"
      >
      </van-nav-bar>
    </van-sticky>
    <!-- top -->
    <div class="wrap">
      <van-cell-group inset>
        <van-cell
          :title="'订单号'"
          :label="currentOrder.order_id"
          v-clipboard:copy="currentOrder.order_id"
          v-clipboard:success="onCopy"
        >
          <template #right-icon>
            <van-icon name="brush-o" size="20" class="search-icon" />
          </template>
        </van-cell>
        <van-cell title="日期" :value="currentOrder.created_at" />
        <van-cell title="确认期限" :value="currentOrder.created_at" />
        <van-cell title="应付金额" :value="currentOrder.amount" />
        <van-cell title="订单状态" :value="currentOrder.status" />
        <van-cell title="户名" :value="currentOrder.bank_owner" />
        <van-cell title="银行" :value="currentOrder.bank_name" />
        <van-cell title="账号" :value="currentOrder.bank_no" />
        <van-cell
          title="支行"
          :value="currentOrder.bank_branch"
          v-if="currentOrder.bank_branch"
        />
        <van-field name="uploader" label="文件上传">
          <template #input>
            <van-uploader
              :before-read="beforeUpload"
              accept="image/*,video/*"
              v-model="currentOrder.files"
              :max-count="3"
            />
          </template>
        </van-field>
        <van-field
          v-model="currentOrder.note"
          rows="2"
          autosize
          label="备注"
          type="textarea"
          placeholder="请输入备注"
        />
      </van-cell-group>
      <van-card
        style="margin: 16px"
        num="1"
        :price="currentOrder.amount"
        :title="currentOrder.content"
        thumb="/tether-usdt-logo.png"
      />
    </div>
    <van-tabbar>
      <template>
        <div class="footBtn">
          <van-button
            style="height: 50px"
            size="normal"
            block
            plain
            hairline
            type="danger"
            @click="$router.push('/ordersC')"
            >取消</van-button
          >
          <van-button
            @click="checkOrder"
            style="height: 50px"
            size="normal"
            block
            type="danger"
            color="linear-gradient(to right, #ff6034, #ee0a24)"
            >提交超时</van-button
          >
        </div>
      </template>
    </van-tabbar>
  </div>
</template>
<script>
import { mapState } from "vuex";
import * as imageConversion from "image-conversion";
export default {
  data() {
    return {
      currentOrder: {},
      setOrderModal: false,
      orderForm: {},
    };
  },
  methods: {
    beforeUpload(files) {
      this.$store.dispatch("setLoadding", true);
      let allFile = [];
      if (!Array.isArray(files)) {
        allFile = [files];
      } else {
        allFile = files;
      }
      return Promise.all(
        allFile.map((file) => {
          if (file.type.indexOf("video") != -1) {
            this.$store.dispatch("setLoadding", false);
            return file;
          }
          return new Promise((resolve, reject) => {
            imageConversion
              .compressAccurately(file, 1000)
              .then((res) => {
                resolve(res);
                this.$store.dispatch("setLoadding", false);
              })
              .catch((err) => {
                this.$toast.fail("请检查文件格式");
                this.$store.dispatch("setLoadding", false);
                reject(err);
              });
          });
        })
      );
    },
    onCopy() {
      this.$toast.success("已复制");
    },
    timeoutOrder() {},
    checkOrder() {
      if (this.currentOrder.files.length == 0) {
        this.$toast.fail("请上传文件");
        return;
      }
      this.setOrderModal = true;
    },
    setOrderBefore(method, done) {
      if (method == "confirm") {
        done(false);
        var formData = new FormData();
        this.currentOrder.files.forEach((e) => {
          formData.append("files[]", e.file);
        });
        if (this.currentOrder.note) {
          formData.append("note", this.currentOrder.note);
        }
        this.$http
          .post(
            `/mobile/orders/${this.currentOrder.order_id}/expired`,
            formData
          )
          .then((res) => {
            if (res.data.code == 0) {
              this.$store.dispatch("getOrder");
              this.$toast.success("成功");
              this.$router.push("/ordersC");
              done();
            } else {
              done(false);
            }
          });
      } else {
        done();
      }
    },
  },
  computed: {
    ...mapState(["orders"]),
  },
  watch: {
    orders() {
      this.orders.orders.forEach((e) => {
        if (e.order_id == this.$route.params.id) [(this.currentOrder = e)];
      });
    },
  },
  created() {
    if (this.orders.orders) {
      this.orders.orders.forEach((e) => {
        if (e.order_id == this.$route.params.id) [(this.currentOrder = e)];
      });
    } else {
      this.$store.dispatch("getOrder");
    }
  },
};
</script>

<style lang="scss" scoped>
.footBtn {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
.dialogInfo {
  padding: 0 5px;
  color: rgb(219, 99, 101);
  font-size: 14px;
}
</style>
